<template>
  <div>
    <modal
      ref="modal"
      class-name="min-h-500px flex flex-col p-10 max-w-full w-full sm:w-700px"
      :can-close="!form.loading"
    >
      <template v-if="template">
        <h5 class="mb-16">{{ template.subject }}</h5>

        <div class="text-xl mb-16 whitespace-pre-line" v-html="template.body" />

        <div class="flex gap-2 justify-end mt-auto">
          <button type="button" class="btn btn-gray" @click="confirmDelete">
            <ion-icon name="trash-outline" class="mr-2" />
            <span>Delete</span>
          </button>
          <button type="button" class="btn btn-blue" @click="edit">
            <ion-icon name="create-outline" class="mr-2" />
            <span>Edit</span>
          </button>
        </div>
      </template>
    </modal>
    <EditCommunicationTemplate
      ref="editModal"
      :template="template"
      @update="emitUpdate"
    />
  </div>
</template>

<script>
export default {
  name: 'PreviewCommunicationTemplate',
  components: {
    EditCommunicationTemplate: () =>
      import(
        '@/components/forms/communications/templates/EditCommunicationTemplate.vue'
      ),
  },
  props: {
    template: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: this.$options.basicForm([]),
    };
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    open() {
      this.$refs.modal.open();
    },
    async edit() {
      this.$refs.editModal.open();
    },
    emitUpdate() {
      this.$emit('update');
    },
    confirmDelete() {
      this.$warning({
        title: 'Are you sure?',
        body: 'You are about to delete a template, please confirm.',
        buttons: [
          {
            text: 'Proceed',
            onClick: this.delete,
          },
          { className: 'btn-orange', text: 'Cancel' },
        ],
      });
    },
    async delete() {
      if (!this.validateForm(this.form)) return;
      this.form.loading = true;
      await this.sendRequest(
        'admin.communications.templates.delete',
        this.template.id,
        {
          data: this.getFormData(),
          success: () => {
            this.form.loading = false;
            this.$emit('update');
            this.$success({
              title: 'Operation Successful',
              body: 'Template was deleted.',
              button: 'Okay',
            });
            this.$nextTick().then(this.close);
          },
          error: (error) => {
            this.$error({ title: error?.response?.data?.message });
          },
        }
      ).finally(() => {
        this.form.loading = false;
      });
    },
  },
};
</script>
